<div class="header-container" [style]="background">
  <div class="logo-container">
    <a (click)="toggleSidebar()"  href="#" class="sidebar-toggle">
      <nb-icon style="color: #fff;" icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo d-none d-md-block" style="color: #fff;" href="#" (click)="navigateHome()"
      >PROCESSO ELETRÔNICO</a
    >
    <a class="logo d-block d-md-none" style="color: #fff;" href="#" (click)="navigateHome()"
    >PROCESSO <br> ELETRÔNICO</a
  >
  </div>
</div>

<div *ngIf="logo" class="header-container d-none d-md-block mr-4" style="background-color: #fff;">
  <img class="logo-container" [src]="logoFinal" alt="">
</div>

<div *ngIf="!logo" class="header-container d-none d-md-block mr-4" style="background-color: #fff;">
  <img class="logo-container" src="/assets/images/tout.png" alt="">
</div>

<div class="header-container" [style]="background">
  <nb-actions class="logo-container" size="small">

    <nb-action class="user-action logo-container" *nbIsGranted="['view', 'user']">
      <nb-user
      class="d-none d-md-block"
        [nbContextMenu]="userMenu"
        [name]="usuario"
        color="#edf1f7"
        [title]="unidadeSelecionada"
      >
      </nb-user>
      <nb-user
      class="d-md-none flex-row-reverse"
        [nbContextMenu]="userMenu"
        color="#edf1f7"
        [name]="usuario"
        onlyPicture
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
