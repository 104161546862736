import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: "pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "pages", pathMatch: "full" },
  {
    path: "organograma",
    loadChildren: () =>
      import("./pages/organograma-m/organograma-m.module").then(
        (m) => m.OrganogramaMModule
      ),
  },
  {
    path: "assunto",
    loadChildren: () =>
      import("./pages/assunto-m/assunto-m.module").then(
        (m) => m.AssuntoMModule
      ),
  },
  {
    path: "instituicao",
    loadChildren: () =>
      import("./pages/entidade-m/entidade-m.module").then(
        (m) => m.EntidadeMModule
      ),
  },
  {
    path: "processo",
    loadChildren: () =>
      import("./pages/processo-m/processo-m.module").then(
        (m) => m.ProcessoMModule
      ),
  },
  {
    path: "usuario",
    loadChildren: () =>
      import("./pages/pessoa-m/pessoa-m.module").then((m) => m.PessoaMModule),
  },
  {
    path: "requerente",
    loadChildren: () =>
      import("./pages/interessado-m/interessado-m.module").then(
        (m) => m.InteressadoMModule
      ),
  },
  // { path: 'geral', loadChildren: () => import('./geral-m/geral-m.module').then(m => m.GeralMModule) },
  { path: "**", redirectTo: "pages" },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
