import { HttpClient } from "@angular/common/http";
import {
  Injectable
} from "@angular/core";
import { Router } from "@angular/router";
import {
  NbComponentStatus,
  NbIconConfig,
  NbToastrService
} from "@nebular/theme";
import { EMPTY, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Card } from "../model/Card.model";
import { EntidadeSimples } from "../model/EntidadeSimples.model";
import { Funcao } from "../model/Funcao.model";
import { FuncoesUnidade } from "../model/FuncoesUnidade.model";
import { Notificacao } from "../model/Notificacao.model";
import { Generico } from "../model/Generico.model";
import { Nivel } from "../model/Nivel.model";
import { TipoUnidade } from "../model/TipoUnidade.model";
import { TipoUnidadeSimples } from "../model/TipoUnidadeSimples.model";
import { Unidade } from "../model/Unidade.model";

@Injectable({
  providedIn: "root",
})
export class UnidadeService {
  pai: string;

  private URL: string = environment.API_URL;

  constructor(
    private http: HttpClient,
    private toastrService: NbToastrService,
    private router: Router
  ) { }

  obterUnidadeSimples(entidade: number): Observable<Unidade[]> {
    return this.http.get<Unidade[]>(
      `${this.URL}/unidades/simples?entidade=${entidade}`
    ).pipe(take(1));
  }

  obterUnidade(entidade: number): Observable<Unidade[]> {
    return this.http.get<Unidade[]>(
      `${this.URL}/unidades?entidade=${entidade}`
    ).pipe(take(1));
  }

  salvarUnidade(
    unidade: Unidade,
    codPai?: number,
    funcoes?: number[]
  ): Observable<Unidade> {
    if (codPai !== undefined) {
      this.pai = `&codigoPai=${codPai}`;
    } else {
      this.pai = "";
    }

    let parametroFuncoes: string = "?funcoes=";

    for (let i = 0; i < funcoes.length; i++) {
      if (i !== 0) {
        parametroFuncoes += ",";
      }
      parametroFuncoes += funcoes[i];
    }

    return this.http.post<Unidade>(
      `${this.URL}/unidades/${parametroFuncoes}${this.pai}`,
      unidade
    ).pipe(take(1));
  }

  buscarUnidadePorId(id: number) {
    return this.http.get<Unidade>(`${this.URL}/unidades/${id}`).pipe(take(1));
  }

  atualizarUnidade(
    unidade: Unidade,
    codPai: number,
    funcoes?: number[]
  ): Observable<Unidade> {
    let parametroFuncoes: string = "?funcoes=";

    for (let i = 0; i < funcoes.length; i++) {
      if (i !== 0) {
        parametroFuncoes += ",";
      }
      parametroFuncoes += funcoes[i];
    }

    return this.http.put<Unidade>(
      `${this.URL}/unidades/${codPai}${parametroFuncoes}`,
      unidade
    ).pipe(take(1));
  }

  deletarUnidade(id: number): Observable<any> {
    return this.http.delete<any>(`${this.URL}/unidades/${id}`).pipe(take(1));
  }

  obterFuncaoPorUnidade(cod: number): Observable<Funcao[]> {
    return this.http.get<Funcao[]>(`${this.URL}/unidades/${cod}/funcoes`).pipe(take(1));
  }

  obterFuncao(entidade: number): Observable<Funcao[]> {
    return this.http.get<Funcao[]>(
      `${this.URL}/funcoes/simples?entidade=${entidade}`
    ).pipe(take(1));
  }

  obterTipos(entidade: number): Observable<TipoUnidade[]> {
    return this.http.get<TipoUnidade[]>(
      `${this.URL}/tiposUnidades?entidade=${entidade}`
    ).pipe(take(1));
  }

  obterTipoSimples(cod: number): Observable<TipoUnidadeSimples[]> {
    return this.http.get<TipoUnidadeSimples[]>(
      `${this.URL}/unidades/${cod}/simples`
    ).pipe(take(1));
  }

  obterPai(cod: number): Observable<FuncoesUnidade> {
    return this.http.get<FuncoesUnidade>(
      `${this.URL}/unidades/${cod}/pai/card`
    ).pipe(take(1));
  }

  obterCard(cod: number): Observable<Unidade[]> {
    return this.http.get<Unidade[]>(
      `${this.URL}/unidades/${cod}/paiEDescendentes/card`
    ).pipe(take(1));
  }

  // unidades/1/descendentes
  getDescendentes(cod: number): Observable<Unidade[]> {
    return this.http.get<Unidade[]>(
      `${this.URL}/unidades/${cod}/descendentes`
    ).pipe(take(1));
  }

  obeterN1N2(cod: number): Observable<Card[]> {
    return this.http.get<Card[]>(
      `${this.URL}/unidades/${cod}/paiEDescendentesNiveis1e2/card`
    ).pipe(take(1));
  }
  // paiEDescendentesNiveis1e2/card

  getUnidadesSimples(entidade: number): Observable<Generico[]> {
    return this.http.get<Generico[]>(
      `${this.URL}/unidades/simples?entidade=${entidade}`
    ).pipe(take(1));
  }

  obterNiveis(entidade: number): Observable<Card[]> {
    return this.http.get<Card[]>(
      `${this.URL}/unidades/card?entidade=${entidade}`
    ).pipe(take(1));
  }

  getFuncoesUnidadeSimples(cod: number): Observable<Generico[]> {
    return this.http.get<Generico[]>(`${this.URL}/unidades/${cod}/funcoes`);
  }

  getCard(entidade: number): Observable<Card[]> {
    return this.http.get<Card[]>(
      `${this.URL}/unidades/card?entidade=${entidade}`
    ).pipe(take(1));
  }

  // unidades/orgaos?entidade=
  getOrgaos(entidade: number): Observable<Generico[]> {
    return this.http.get<Generico[]>(
      `${this.URL}/unidades/orgaos?entidade=${entidade}`
    ).pipe(take(1));
  }

  //
  //  /unidades/${cod}/card
  //
  getFuncoesCardById(cod: number): Observable<Card[]> {
    // return this.http.get<Card[]>(`${this.URL}/unidades/${cod}/paiEDescendentes/card`);
    return this.http.get<Card[]>(`${this.URL}/unidades/${cod}/card`).pipe(take(1));
  }

  // http://localhost:8080/protocolos-0.0.1/unidades/niveis
  getNivel(entidade: number): Observable<Nivel[]> {
    return this.http.get<Nivel[]>(
      `${this.URL}/unidades/niveis?entidade=${entidade}`
    ).pipe(take(1));
  }

  // http://localhost:8080/protocolos-0.0.1/unidades/1/filhos
  obterFilhos(cod: number): Observable<Card[]> {
    return this.http.get<Card[]>(`${this.URL}/unidades/${cod}/filhos/card`).pipe(take(1));
  }

  obterFilhoNivel(cod): Observable<Card> {
    return this.http.get<Card>(`${this.URL}/unidades/${cod}/filhos/nivel`).pipe(take(1));
  }

  tiposOrganograma(entidade: number): Observable<Card[]> {
    return this.http.get<Card[]>(
      `${this.URL}/unidades/nivel/1/card?entidade=${entidade}`
    ).pipe(take(1));
  }

  getEntidades(): Observable<EntidadeSimples[]> {
    return this.http.get<EntidadeSimples[]>(
      `${this.URL}/entidades`
    ).pipe(take(1));
  }

  postEntidade(entidade: EntidadeSimples): Observable<EntidadeSimples> {
    return this.http.post<EntidadeSimples>(
      `${this.URL}/entidades`, entidade
    ).pipe(take(1));
  }

  getEntidadeById(cod: number): Observable<EntidadeSimples> {
    return this.http.get<EntidadeSimples>(
      `${this.URL}/entidades/${cod}`
    ).pipe(take(1));
  }

  // entidades/1
  putEntidade(cod: number, entidade: EntidadeSimples) {
    return this.http.put<Unidade>(
      `${this.URL}/entidades/${cod}`, entidade
    ).pipe(take(1));
  }

  updateUnidade(unidade: Unidade, CodTipo: number, funcoes: number[]) { }

  exibeErroPost(e: any): Observable<any> {
    this.exibirMensagem("ERRO!", e, 5000, "danger");
    return EMPTY;
  }

  // notificacaoAssunto?fluxo=49
  getNotificacaoById(cod: number): Observable<Notificacao[]> {
    return this.http.get<Notificacao[]>(
      `${this.URL}/notificacaoAssunto?fluxo=${cod}`
    ).pipe(take(1));

  }

  // POST /notificacaoAssunto
  postNotificacaoEmail(emails: any): Observable<Notificacao> {
    return this.http.post<Notificacao>(
      `${this.URL}/notificacaoAssunto`, emails
    ).pipe(take(1));
  }

  // unidadeAssunto?assunto=59
  getUnidadeAssunto(cod: number): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.URL}/unidadeAssunto?assunto=${cod}`
    ).pipe(take(1));

  }

  // unidadeAssunto/3213213
  ativarUnidadeAssunto(id: number) {
    return this.http.patch(
      `${this.URL}/unidadeAssunto/${id}`, {}).pipe(take(1));
  }

  // DELETE /unidadeAssunto/{cod}
  deleteUnidadeAssunto(id: number) {
    return this.http.delete<any>(`${this.URL}/unidadeAssunto/${id}`).pipe(take(1));
  }

  postUnidadeAssunto(unidades: any): Observable<any[]> {
    return this.http.post<any[]>(
      `${this.URL}/unidadeAssunto`, unidades
    ).pipe(take(1));
  }

  exibirMensagem(
    titulo: string,
    mensagem: string,
    duracao: number,
    status?: NbComponentStatus,
    icone = "alert-circle-outline"
  ) {
    if (mensagem !== undefined) {
      const iconConfig: NbIconConfig = {
        icon: icone,
        pack: "eva",
      };
      this.toastrService.show(mensagem, titulo, {
        icon: iconConfig,
        limit: 1,
        duration: duracao,
        status: status,
      });
    }
  }
}
