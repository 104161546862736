<nb-layout windowMode>
  <nb-layout-header class="d-none d-md-block" [style]="background" fixed>
    <ngx-header></ngx-header>
  </nb-layout-header>
  <nb-layout-header class="d-block d-md-none" [style]="back" fixed>
    <ngx-header></ngx-header>
  </nb-layout-header>
  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
    <ng-content select="nb-menu"></ng-content>
  </nb-sidebar>
  <nb-layout-column>
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>
</nb-layout>