import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { LocalStorageService } from "../../../services/local-storage.service";

@Component({
  selector: "ngx-one-column-layout",
  templateUrl: "./one-column.layout.html",
  styleUrls: ["./one-column.layout.scss"],
})
export class OneColumnLayoutComponent implements OnInit {
  corHeader: string;
  background: string;
  back: string;

  paginas = [
    {
      nome: "Home",
      path: "",
    },
    {
      nome: "Home",
      path: "",
    },
    {
      nome: "Home",
      path: "",
    },
  ];

  constructor(private router: Router, private storage: LocalStorageService) {}

  ngOnInit() {
    const url = this.router.url;
    this.corHeader = this.storage.get("corHeader");
    if (!this.storage.get("corHeader")) {
      this.corHeader = "#f8842f";
    }
    this.back = `background-color: ${this.corHeader};`;
    this.background = `background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 23%, ${this.corHeader} 33%);`;
  }
}
